.errorBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #893232;
    background-color: #fadddd;
    border-radius: .25rem;
}

.errorText {
    color: #893232;
    padding-left: 10px;
}

.X {
    color: #893232;
    padding-right: 10px;
    cursor: pointer;
}