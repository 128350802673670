.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    height: 100%;
}

.centerBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 25vw;
    height: 35vh;
    background-color: white;
    border-radius: 10px;
    padding: 30px;
}

.logo {
    max-width: 250px;
}

input {
    width: 100%;
    height: 30px;
    font-size: large;
    border: none;
    border-color: rgb(201, 198, 198);
    border-bottom: 2px solid rgb(201, 198, 198);
    margin-top: 30px;
}

input:focus {
    outline: none;
    border-bottom: 2px solid rgb(145, 144, 144);
}

.dbutton {
    margin-top: 5px;
    background-color: #ffd51f;
    border: none;
    color: #111;
    padding: 12px 32px;
    text-align: center;
    display: block;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    font-size: 20px;
}

.width-full {
    width: 100%;
}

.label {
    margin-top: 300px;
    color: #979797
}

.text {
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .centerBox {
        width: 80vw;
        height: 80vh;
    }
}
