.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.footerRow {
    display: flex;
    justify-content: flex-end;
    color: white;
    text-align: right;
    font-size: 1rem;
}

.footerContent {
    display: flex;
    justify-content: flex-end;
}

.item {
    margin-right: 10px;
}
.pointer{
    cursor: pointer;
}